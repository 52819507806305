import request from '@/utils/request'

/**
 * 获取账号列表
 */
export const accountList = (params) => {
  return request({
    url: '/account/list',
    method: 'GET',
    params
  })
}

/**
 * 新增账号
 */
export const addAccount = (data) => {
  return request({
    url: '/account/add',
    method: 'POST',
    data
  })
}

/**
 * 修改账号
 */
export const editAccount = (data) => {
  return request({
    url: '/account/update',
    method: 'POST',
    data
  })
}

/**
 * 删除账号
 */
export const deleteAccount = (id) => {
  return request({
    url: '/account/delete',
    method: 'POST',
    data: {
      id: id
    }
  })
}
