<template>
  <el-dialog
    :title="isEdit ? '编辑账号' : '新增账号'"
    :model-value="modelValue"
    @close="closed"
    width="600px"
  >
    <el-form
      class="create-form"
      ref="CreateFromRef"
      :model="createForm"
      :rules="createRules"
      label-width="120px"
      size="small"
    >
      <el-form-item label="账号:" prop="spa_account">
        <el-input
          v-model="createForm.spa_account"
          placeholder="请输入"
          name="spa_account"
          type="text"
        />
      </el-form-item>

      <el-form-item label="密码:" prop="spa_password">
        <el-input
          v-model="createForm.spa_password"
          placeholder="请输入"
          name="spa_password"
          type="text"
        />
      </el-form-item>
      <el-form-item label="所属店铺:" prop="company_id">
        <el-select v-model="createForm.company_id" placeholder="请选择">
          <el-option
            v-for="list in companyList"
            :key="list.id"
            :label="list.name"
            :value="list.id"
          />
        </el-select>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="closed">取消</el-button>
        <el-button type="primary" @click="onConfirmClick" :loading="loading"
          >确定</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { ref, defineProps, defineEmits, watch } from 'vue'
import { addAccount, editAccount } from '@/api/account'
import { ElMessage } from 'element-plus'

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true
  },
  isEdit: {
    type: Boolean,
    default: false
  },
  editInfo: {
    type: Object
  },
  companyList: {
    type: Array,
    defalt: () => []
  }
})

const emits = defineEmits(['update:modelValue', 'refresh'])

// 数据源
const loading = ref(false)
const createForm = ref({
  spa_account: '',
  spa_password: '',
  company_id: ''
})
// 验证规则
const createRules = ref({
  spa_account: [
    {
      required: true,
      trigger: 'blur',
      message: '请输入账号'
    }
  ],
  spa_password: [
    {
      required: true,
      trigger: 'blur',
      message: '请输入密码'
    }
  ],
  company_id: [
    {
      required: true,
      trigger: 'blur',
      message: '请选择店铺'
    }
  ]
})

const CreateFromRef = ref(null)

watch(
  () => props.modelValue,
  (val) => {
    if (val) {
      if (props.isEdit) {
        const formData = {}
        Object.keys(createForm.value).forEach((key) => {
          formData[key] = props.editInfo[key]
        })
        createForm.value = formData
      }
    } else {
      CreateFromRef.value.resetFields()
    }
  }
)

/**
 * 关闭
 */
const closed = () => {
  emits('update:modelValue', false)
}

/**
 * 触发表单验证
 */
const onConfirmClick = () => {
  CreateFromRef.value.validate((isValid) => {
    if (isValid) {
      props.isEdit ? postEdit() : postCreate()
    }
  })
}

/**
 * 提交新增
 */
const postCreate = async () => {
  loading.value = true
  await addAccount(createForm.value)
  loading.value = false
  ElMessage.success('新增账号成功')
  emits('refresh')
  closed()
}

/**
 * 提交修改
 */
const postEdit = async () => {
  loading.value = true
  await editAccount({
    ...createForm.value,
    id: props.editInfo.id
  })
  loading.value = false
  ElMessage.success('修改账号成功')
  emits('refresh')
  closed()
}
</script>

<style lang="scss" scoped>
.create-form {
  ::v-deep .el-input {
    width: 400px;
  }
}
</style>
